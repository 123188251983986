import { FolderArrowDownIcon, HomeIcon, HomeModernIcon, MagnifyingGlassCircleIcon, RocketLaunchIcon, UserGroupIcon } from "@heroicons/react/20/solid";
import { ChatBubbleBottomCenterIcon, ChatBubbleLeftEllipsisIcon } from "@heroicons/react/24/outline";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import logo from '../assets/images/THM-DARK-BLUE-TRANSPARENT.png';

const SidebarMenuMobile = ({ sideBar, toggleSidebar }) => {
    const unreadCount = useSelector(state => state.pusher.unread);
    const [unreadMessageCount, setUnreadMessageCount] = useState(0);
    const profileData = useSelector(state => state.user.profile);
    const sidebarRef = useRef();

    useEffect(() => {
        if (unreadCount?.total_unread_count) {
            setUnreadMessageCount(unreadCount.total_unread_count);
        } else {
            setUnreadMessageCount(0);
        }
    }, [unreadCount]);

    useEffect(() => {
        console.log("SidebarMenu mounted");
    }, []);


    // Close sidebar when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (sidebarRef.current && !sidebarRef.current.contains(event.target) && sideBar) {
                toggleSidebar();
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [sideBar, toggleSidebar]);

    return (
        <>
            {/* Overlay to close sidebar on outside click */}
            {sideBar && (
                <div
                    className="fixed inset-0 bg-black opacity-50 z-30"
                    onClick={toggleSidebar}
                ></div>
            )}
            <aside
                ref={sidebarRef}
                className={`fixed top-0 left-0 z-40 w-55 h-screen pt-20 transition-transform transform ${sideBar ? 'translate-x-0' : '-translate-x-full'
                    } border-r border-gray-900 bg-gray-900 border-gray-700`}
                aria-label="Sidebar"
            >
                <div className="h-full px-3 pb-4 overflow-y-auto bg-gray-900">
                    <div className="flex items-center justify-center">
                        <NavLink to="/" onClick={toggleSidebar} exact="true">
                            {profileData?.agency?.logo ? (
                                <img src={`data:${profileData.agency.logo.type};base64,${profileData.agency.logo.content}`} width={170} className="mx-auto" />
                            ) : (
                                <img src={logo} width={170} className="mx-auto" />
                            )}
                        </NavLink>
                    </div>
                    <ul className="space-y-2 font-bold mt-2">
                        <li>
                            <NavLink to="/home" onClick={toggleSidebar} exact="true" className="flex items-center p-2 text-gray-900 rounded-lg text-white hover:bg-gray-100 hover:bg-gray-700 group">
                                <HomeIcon className="h-6 w-6 text-white" />
                                <span className="flex-1 ms-3 whitespace-nowrap">Accueil</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink onClick={toggleSidebar} to="/page/matching" exact="true" className="flex items-center p-2 text-gray-900 rounded-lg text-white hover:bg-gray-100 hover:bg-gray-700 group">
                                <RocketLaunchIcon className="h-6 w-6 text-white" />
                                <span className="flex-1 ms-3 whitespace-nowrap">Mes Matchs</span>
                            </NavLink>
                        </li>

                        <li>
                            <NavLink onClick={toggleSidebar} to="/page/portefeuille-client" exact="true" className="flex items-center p-2 text-gray-900 rounded-lg text-white hover:bg-gray-100 hover:bg-gray-700 group">
                                <UserGroupIcon className="h-6 w-6 text-white" />
                                <span className="flex-1 ms-3 whitespace-nowrap">Mes Acquéreurs</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink onClick={toggleSidebar} to="/page/portefeuille-bien" exact="true" className="flex items-center p-2 text-gray-900 rounded-lg text-white hover:bg-gray-100 hover:bg-gray-700 group">
                                <HomeModernIcon className="h-6 w-6 text-white" />
                                <span className="flex-1 ms-3 whitespace-nowrap">Mes Biens <br />Off Market</span>
                            </NavLink>
                        </li>

                        <li>
                            <NavLink onClick={toggleSidebar} to="/page/recherche-biens" exact="true" className="flex items-center p-2 text-gray-900 rounded-lg text-white hover:bg-gray-100 hover:bg-gray-700 group">
                                <MagnifyingGlassCircleIcon className="h-6 w-6 text-white" />
                                <span className="flex-1 ms-3 whitespace-nowrap">Recherche</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink onClick={toggleSidebar} to="/page/synthese-rapprochement" exact="true" className="flex items-center p-2 text-gray-900 rounded-lg text-white hover:bg-gray-100 hover:bg-gray-700 group">
                                <FolderArrowDownIcon className="h-6 w-6 text-white" />
                                <span className="flex-1 ms-3 whitespace-nowrap">Synthèse <br />Rapprochement</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink onClick={toggleSidebar} to="/messagerie" className="flex items-center p-2 text-gray-900 rounded-lg text-white hover:bg-gray-100 hover:bg-gray-700 group">
                                <svg className="flex-shrink-0 w-5 h-5 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="m17.418 3.623-.018-.008a6.713 6.713 0 0 0-2.4-.569V2h1a1 1 0 1 0 0-2h-2a1 1 0 0 0-1 1v2H9.89A6.977 6.977 0 0 1 12 8v5h-2V8A5 5 0 1 0 0 8v6a1 1 0 0 0 1 1h8v4a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-4h6a1 1 0 0 0 1-1V8a5 5 0 0 0-2.582-4.377ZM6 12H4a1 1 0 0 1 0-2h2a1 1 0 0 1 0 2Z" />
                                </svg>
                                <span className="flex-1 ms-3 whitespace-nowrap">Messagerie</span>
                                {unreadMessageCount > 0 && (
                                    <span className="inline-flex items-center justify-center w-3 h-3 p-3 ms-3 text-sm font-bold text-white-800  rounded-full bg-red-900">
                                        {unreadMessageCount}
                                    </span>
                                )}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink onClick={toggleSidebar} to="https://thehushmarket.com/contact/" className="flex items-center p-2 text-gray-900 rounded-lg text-white hover:bg-gray-100 hover:bg-gray-700 group">
                                <ChatBubbleBottomCenterIcon className="h-6 w-6 text-white" />
                                <span className="flex-1 ms-3 whitespace-nowrap">Support</span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </aside>
        </>
    );
};

export default SidebarMenuMobile;
