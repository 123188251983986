import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const Footer = () => {
	const { t } = useTranslation();
	const { isLoggedIn } = useSelector(state => state.auth);
	return (
		<footer className={`${isLoggedIn ? 'sm:ml-60 ' : 'pt-10'} px-5`} style={{ color: "gray", bottom: 0 }}>
			<div className="grid gap-16 row-gap-10 mb-8 lg:grid-cols-6">
				<div className="md:max-w-md lg:col-span-2">
					<a
						href="/"
						aria-label="Go home"
						title="Company"
					>

						<span className="text-xl text-white">
							The <b>Hush</b> Market
						</span>
					</a>
					<div className="mt-2 lg:max-w-sm">
						<p className="text-sm text-white">
							La plateforme n°1 pour la vente et l’achat de bien immobilier off-market
						</p>
					</div>
				</div>
				<div className="grid grid-cols-2 gap-5 row-gap-8 lg:col-span-4 md:grid-cols-2">
					{/* <div>
						<p className="font-semibold tracking-wide text-white">
							Site
						</p>
						<ul className="mt-2 space-y-2">
							<li>
								<a
									href="https://thehushmarket.com/pourquoi-le-off-market/"
									className="text-slate-400 transition-colors duration-300 hover:text-deep-purple-accent-400"
								>
									Le Off Market
								</a>
							</li>
							<li>
								<a
									href="https://thehushmarket.com/le-rapprochement-automatique/"
									className="text-slate-400 transition-colors duration-300 hover:text-deep-purple-accent-400"
								>
									L'outil de Rapprochement
								</a>
							</li>
							<li>
								<a
									href="https://thehushmarket.com/a-propose-de-nous/"
									className="text-slate-400 transition-colors duration-300 hover:text-deep-purple-accent-400"
								>
									A propos de nous
								</a>
							</li>
							<li>
								<a
									href="https://thehushmarket.com/blog/"
									className="text-slate-400 transition-colors duration-300 hover:text-deep-purple-accent-400"
								>
									Blog
								</a>
							</li>
							<li>
								<a
									href="https://thehushmarket.com/programme-ambassadeur/"
									className="text-slate-400 transition-colors duration-300 hover:text-deep-purple-accent-400"
								>
									Programme ambassadeur
								</a>
							</li>
						</ul>
					</div> */}

					<div>
						{/* <p className="font-semibold tracking-wide text-white">Support</p> */}
						<ul className="mt-2 space-y-2">
							<li>
								<a
									href="https://thehushmarket.com/politique-de-confidentite-donnees/"
									className="text-slate-400 transition-colors duration-300 hover:text-deep-purple-accent-400"
								>
									Politique de confidentialité des données
								</a>
							</li>
							<li>
								<a
									href="https://thehushmarket.com/mentions-legales-et-gestion-des-cookies/"
									className="text-slate-400 transition-colors duration-300 hover:text-deep-purple-accent-400"
								>
									Mention légale et Gestion des Cookies
								</a>
							</li>
							<li>
								<a
									href="https://thehushmarket.com/conditions-generales-de-vente/"
									className="text-slate-400 transition-colors duration-300 hover:text-deep-purple-accent-400"
								>
									Conditions Générales de Vente
								</a>
							</li>
							<li>
								<a
									href="https://thehushmarket.com/condition-generales-de-utilisation/"
									className="text-slate-400 transition-colors duration-300 hover:text-deep-purple-accent-400"
								>
									Conditions Générales d'Utilisation
								</a>
							</li>
							<li>
								<a
									href="https://thehushmarket.com/contact/"
									className="text-slate-400 transition-colors duration-300 hover:text-deep-purple-accent-400"
								>
									Contact
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div className="flex flex-col justify-between pt-5 pb-10 border-t sm:flex-row">
				<p className="text-sm text-slate-400">
					© 2024
				</p>
			</div>
		</footer>
	);
};

export default Footer;