import { honoraryTypes, matchingRequestTypes, propertyAnnexes, propertyPublishListing, propertyStates, propertyTypes, staffTypes } from "app/constants";

export const getLanguageName = languageId => {
	let languageName = '';
	switch (languageId) {
		case 1:
			languageName = 'fr';
			break;
		case 3:
			languageName = 'de';
			break;
		default:
			languageName = 'en';
			break;
	}
	return languageName;
};

export const getLabelMatchingRequestType = (matchingRequestType) => {
	const foundType = matchingRequestTypes.find(type => type.value === matchingRequestType);
	return foundType ? foundType.label : 'Unknown';
};

export const getStaffType = (staffType) => {
	const foundType = staffTypes.find(type => type.value === staffType);
	return foundType ? foundType.label : 'Unknown';
};

export const getLabelForTypeId = (typeId) => {
	const foundType = propertyTypes.find(type => type.value === typeId);
	return foundType ? foundType.label : 'Unknown';
};

export const getLabelForHonoraryTypeId = (honoraryTypeId) => {
	const foundType = honoraryTypes.find(type => type.value === honoraryTypeId);
	return foundType ? foundType.label : 'Unknown';
};

export const getLabelForAnnexeId = (annexeId) => {
	const foundAnnexe = propertyAnnexes.find(annexe => annexe.value === annexeId);
	return foundAnnexe ? foundAnnexe.label : 'Unknown';
};

export const getLabelForPropertyStateId = (propertyStateId) => {
	const foundPropertyState = propertyStates.find(state => state.value === propertyStateId);
	return foundPropertyState ? foundPropertyState.label : 'Unknown';
};

export const getLabelForPropertyPublishListingId = (propertyPublishListingId) => {
	const foundPublishListing = propertyPublishListing.find(state => state.value === propertyPublishListingId);
	return foundPublishListing ? foundPublishListing.label : '';
};

export const formatPrice = (price) => {
	// Format the price
	if (!price) {
		return "XXXXX"
	}
	return new Intl.NumberFormat('fr').format(price);
};

export const prepareMessages = (response, type = 'error') => {
	// const messagePrefix = type === 'error' ? 'Error' : type.charAt(0).toUpperCase() + type.slice(1);
	const messagePrefix = ''
	const messages = [];
	if (response?.response?.status === 409) {
		messages.push({ type: type, name: messagePrefix, message: response?.response?.data?.message });
	} else if (response?.payload?.data && Object.keys(response?.payload?.data).length > 0) {
		Object.entries(response?.payload?.data).forEach(([key, value]) => {
			messages.push({ type: type, name: key, message: value });
		});
	} else if (response?.data && Object.keys(response?.data).length > 0) {
		Object.entries(response?.data).forEach(([key, value]) => {
			messages.push({ type: type, name: key, message: value });
		});
	} else if (response?.payload?.message) {
		messages.push({ type: type, name: messagePrefix, message: response?.payload?.message });
	} else if (response?.payload) {
		messages.push({ type: type, name: messagePrefix, message: response?.payload });
	} else if (response?.message) {
		messages.push({ type: type, name: messagePrefix, message: response?.message });
	} else {
		messages.push({ type: type, name: messagePrefix, message: response });
	}
	return messages;
};

export const formatFeatureKey = (key, value = null) => {
	switch (key) {
		case 'thread_access':
			return 'Messagerie privée';
		case 'owner_number':
			if (value > 1) {
				return value + ' agences';
			}
			return value + ' agence';
		case 'properties_number':
			if (value == -1) {
				return 'Nombre de biens illimité';
			}
			return value + ' biens';
		case 'staff_number':
			if (value > 1) {
				return value + ' utilisateurs';
			}
			return value + ' utilisateur';
		case 'matching_request_access':
			return 'Outil de rapprochement';
		case 'support_priority':
			return 'Support prioritaire';
		default:
			return key; // If key is not recognized, return it as is
	}
};

export const getIntervalText = (interval) => {
	switch (interval) {
		case 'half_yearly':
			return 'tous les 6 mois';
		case 'yearly':
			return 'par an';
		default:
			return 'par mois';
	}
};

export const shouldHideSidebar = (pathname) => {
	// Paths where the sidebar should be hidden
	const hideSidebarForPaths = [
		'/mot-de-passe-oublie'
	];

	// Check for dynamic routes
	if (pathname.startsWith('/mot-de-passe-oublie/')) {
		return true;
	}

	// Static route checks
	return hideSidebarForPaths.includes(pathname);
};

